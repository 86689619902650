import React, { useState } from "react";
import Modal from "react-modal";
import { format } from "date-fns";
import Loader from "../components/loader";
import Pdf from "../documents/rules.pdf";

Modal.setAppElement("#___gatsby");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#E19C67",
    padding: "5px"
  }
};

const OrderModal = ({
                      modalIsOpen,
                      closeModal,
                      bath,
                      sum,
                      date,
                      start,
                      end,
                      load,
                      people,
                      attendant,
                      chan,
                      soaring,
                      removeName,
                      removePhone,
                      removeBath,
                      removeDate,
                      removeStart,
                      removeEnd,
                      removePeople,
                      removeChan,
                      removeAttendant,
                      removeSoaring,
                      removeSum,
                      removeChanVisible,
                      removeAttendantVisible,
                      submit,
                      setTimeError,
                      setNameError,
                      setPhoneError
                    }) => {

  const [offer, setOffer] = useState(false);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className="main-content main-content__modal">
          <h2>Ваш заказ</h2>
          <p className="modal-subtitle">{bath}</p>
          <div className="modal-list">
            <p>
              Дата посещения:
              {" "}
              <strong>{String(format(new Date(date), "dd.MM.yyyy"))}</strong>
            </p>
            <p>
              Время посещения:
              {" "}
              c
              {" "}
              <strong>{start}</strong>
              {" "}
              до
              {" "}
              <strong>{end}</strong>
            </p>
            <p>
              Колличество человек:
              {" "}
              <strong>{people}</strong>
            </p>
            {
              (chan || (attendant && soaring)) &&
              <p>
                Дополнительно:
                {" "}
                {chan && <strong>Чан,</strong>}
                {" "}
                {attendant && soaring > 0 && <strong>Парение - {soaring} чел.</strong>}
              </p>
            }
            <p>
              Стоимость: <strong>{sum} руб.</strong>
            </p>
          </div>
          <div className="offer">
            <input
              type="checkbox"
              id="offer"
              name="offer"
              value="false"
              className="offer-checkbox"
              onChange={(e) => setOffer(!offer)}
            />
            <label htmlFor="offer" className="offer-label">
              {" "}
              С <a href={Pdf} target="_blank" rel="noreferrer">правилами</a> пользования баней ознакомлен
            </label>
          </div>
          <div className="modal-control">
            <button
              onClick={() => {
                closeModal()
                removeName('')
                removePhone('')
                removeBath('Баня по-черному')
                removeDate(new Date())
                removeStart('')
                removeEnd('')
                removePeople(1)
                removeChan(false)
                removeSoaring(0)
                removeSum(0)
                removeChanVisible(false)
                removeAttendant(false)
                removeAttendantVisible(false)
                setTimeError(false)
                setNameError(true)
                setPhoneError(true)
              }}
              aria-label="close modal"
              className="modal-btn"
            >
              Отменить
            </button>
            <button
              disabled={!offer}
              className="snipcart-add-item order-modal-btn"
              onClick={submit}
            >
              {load ? <Loader /> : "Оплатить"}
            </button>
            {/*{*/}
            {/*  offer*/}
            {/*    ?*/}
            {/*    <a*/}
            {/*      className="snipcart-add-item order-modal-btn"*/}
            {/*      href={link} target="_blank"*/}
            {/*    >*/}
            {/*      Оплатить*/}
            {/*    </a>*/}
            {/*    :*/}
            {/*    <button*/}
            {/*      disabled={!offer}*/}
            {/*      className="snipcart-add-item order-modal-btn"*/}
            {/*    >*/}
            {/*      Оплатить*/}
            {/*    </button>*/}
            {/*}*/}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OrderModal;