/**Страница заказа*/
import React from "react";
import Layout from "../components/layout";
import Banner from "../components/banner";
import OrderForm from "../components/order-form";

const Order = () => (

  <Layout
    metaContent="Закажите традиционную русскую баню в уедененном тихом месте на берегу реки Оби!"
    title="Затонские Бани. Заказ Бани"
    keywords="аренда бани в барнауле, заказать баню по серому в барнауле, заказать баню по черному в барнауле"
  >
    <Banner
      anchor="service"
      buttonText="Подробнее"
    />
    <OrderForm />
  </Layout>
);
export default Order;
