import React, { useState, useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { Link } from "gatsby";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import {
  format,
  addDays,
  startOfTomorrow,
  sub,
  startOfHour,
  add,
  addMinutes
} from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import OrderModal from "../components/order-modal";
import SuccessfulModal from "../components/successful-modal";
import ErrorModal from "../components/error-modal";
import Loader from "../components/loader";
import { gapi } from "gapi-script";
import CryptoJS from "rn-crypto-js";
// import { useAuthValue } from "./Auth/AuthContext";
import InputMask from 'react-input-mask';
import NightLockModal from "./lock-modal";

/**Регистрация локали для react-datepicker*/
registerLocale("ru", ru);

const Form = () => {
  const blackBath = "https://res.cloudinary.com/dlhsp2kx2/image/upload/c_scale,w_600,q_90,f_webp/v1694593848/main_page/banya.webp";
  const grayBath = "https://res.cloudinary.com/dlhsp2kx2/image/upload/c_scale,w_600,q_90,f_webp/v1694577775/main_page/bath.webp"

  const DISCOVERY_DOCS_TABLE = ["https://sheets.googleapis.com/$discovery/rest?version=v4"];

  const SPREADSHEET_ID = "1bxJsPl_675kMEPzBG_qFZQ8GaToMesumrrKDT9Tngv8";

  const API_KEY = "AIzaSyAoRRN-VM8H5qgjZGbIQvQEmnKUGIrwrJY"

  /**Услуги*/
  const [services, setServices] = useState([])

  /**Начало и окончание праздничных дней*/
  const [startHolidays, setStartHolidays] = useState('')
  const [endHolidays, setEndHolidays] = useState('')

  /**Поля имени и телефона пользователя сохраненные в контексте*/
  // const { userName, userPhone } = useAuthValue();
  const [userName, setUserName] = useState('')
  const [userPhone, setUserPhone] = useState('')
  const [nameError, setNameError] = useState(true)
  const [phoneError, setPhoneError] = useState(true)

  const modalRef = useRef(null);

  let temp = [];

  /**Текущий час*/
  const currentHour = +format(startOfHour(new Date()), "H") + 1;

  /**Получение услуг из гугл таблицы*/
  const getServices = () => {
    try {
      gapi.load("client", () => {
        gapi.client.init({
          apiKey: API_KEY,
          discoveryDocs: DISCOVERY_DOCS_TABLE,
          plugin_name: "streamy"
        }).then(() => {

          return gapi.client.sheets.spreadsheets.values.get({
            spreadsheetId: SPREADSHEET_ID,
            range: 'Banya_Uslugi!A1:C1000', // for example: List 1!A1:B6
          }).then((res) => {
            setServices(res.result.values)
          })
        })
      })
    } catch (err) {
      return console.log(err.message);
    }
  }

  /**Получение праздников из гугл таблицы*/
  const getHolidays = () => {
    gapi.load("client", () => {
      gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: DISCOVERY_DOCS_TABLE,
        plugin_name: "streamy"
      }).then(() => {
        return gapi.client.sheets.spreadsheets.values.get({
          spreadsheetId: SPREADSHEET_ID,
          range: 'Banya_Prazdniki!A1:B1000', // for example: List 1!A1:B6
        }).then((res) => {
          let values = res.result.values[1]
          setStartHolidays(values[0])
          setEndHolidays(values[1])
        })
      })
    })
  }

  useEffect(() => {
    getServices();
    getHolidays();
  }, [])


  const servicesRows = services.slice(1).flat();

  /**Сохраняем стоимость в переменных*/
  const costBlack = +servicesRows[11];
  const costGray = +servicesRows[2];
  const costBlackWeekend = +servicesRows[17];
  const costGrayWeekend = +servicesRows[8];
  const costChan = +servicesRows[20];
  const costSoaring = +servicesRows[23];
  const overpayment = +servicesRows[26];
  const costBlackEvening = +servicesRows[14];
  const costGrayEvening = +servicesRows[5];

  /**Сумма*/
  const [sum, setSum] = useState(0);

  /**Баня*/
  const [bath, setBath] = useState("Баня по-черному");

  /**Колличество человек*/
  const [peopleNum, setPeopleNum] = useState(1);

  /**Колличество паруемых*/
  const [soaringPeopleNum, setSoaringPeopleNum] = useState(1);

  /**Парильщик*/
  const [attendant, setAttendant] = useState(false);

  /**Видимость Парильщика*/
  const [attendantVisible, setAttendantVisible] = useState(false);

  /**Видимость Чана*/
  const [chanVisible, setChanVisible] = useState(false);

  /**Чан*/
  const [chan, setChan] = useState(false);

  /**Установка даты в датапикере*/
  const [startDate, setStartDate] = useState(new Date());

  /**Выходные дни*/
  const saturday = new Date(startDate).getDay() === 0;
  const sunday = new Date(startDate).getDay() === 6;
  const friday = new Date(startDate).getDay() === 5;

  /**Расписание*/
  const weekdayStartTimes = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];

  /**Массив содержащий свободные часы начала*/
  const [startTimeslots, setStartTimeslots] = useState([]);

  /**Массив содержащий свободные часы окончания*/
  const [endTimeslots, setEndTimeslots] = useState([]);

  const [dublicateTimeslots, setDublicateTimeslots] = useState([]);

  /**Прелоадер внутри кнопки*/
  const [loading, setLoading] = useState(false);

  /**Валидация времени*/
  const [timeError, setTimeError] = useState(false);
  const [startTimeError, setStartTimeError] = useState(false);
  const [endTimeError, setEndTimeError] = useState(false);
  const [dateError, setDateError] = useState(false);

  /**Видимость списока начала свободного времени*/
  const [startTimePopup, setStartTimePopup] = useState(false);

  /**Свободное время (начало)*/
  const [startTime, setStartTime] = useState("");

  /**Свободное время (окончание)*/
  const [endTime, setEndTime] = useState("");

  /**Видимость списка окончания свободного времени*/
  const [endTimePopup, setEndTimePopup] = useState(false);

  const disable = timeError && !nameError && !phoneError;

  /**Доступ к методам службы обнаружения Api google*/
  const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];

  /**Включение/выключение модального окна*/
  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = (e) => {
    let currHour = new Date().getHours()
    let currDate = Date.parse(format(new Date(startDate), 'yyyy-MM-dd'))
    let newDate = Date.parse(format(new Date(), 'yyyy-MM-dd'))
    e.preventDefault();
    ((currHour >= 0) && (currHour < 8) && (currDate === newDate)) ? openLockModal(startDate) : setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  /**Включение/выключение модальных окон успешно/ошибка*/
  const [successfulModal, setSuccessfulModal] = useState(false);
  const [nightLockModal, setNightLockModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const openSuccessfulModal = () => {
    setSuccessfulModal(true);
  };
  const closeSuccessfulModal = () => {
    setSuccessfulModal(false);
  };
  const openErrorModal = () => {
    setErrorModal(true);
  };
  const closeErrorModal = () => {
    setErrorModal(false);
  };

  /**Храним значение занятого чана*/
  const [busyChan, setBusyChan] = useState(true);

  /**Проверяем занят чан или нет*/
  const checkChan = (ev) => {
    let start = +startTime.replace(":00", "");
    let end = +ev.target.value.replace(":00", "");

    // Приводим часы после 24:00 к нужному формату
    switch (end) {
      case 0:
        end = 24;
        break;
      case 1:
        end = 25;
        break;
      case 2:
        end = 26;
        break;
      case 3:
        end = 27;
        break;
      case 4:
        end = 28;
        break;
    }
    ev.persist();
    //Получаем массив событий из календаря отфильтрованый по выбранной дате
    gapi.load("client", () => {
      gapi.client.init({
        apiKey: "AIzaSyAoRRN-VM8H5qgjZGbIQvQEmnKUGIrwrJY",
        discoveryDocs: DISCOVERY_DOCS,
        plugin_name: "streamy"
      }).then(() => {
        return gapi.client.calendar.events.list({
          calendarId: "kmkev9s0o0tf198ddtfr8sfgdo@group.calendar.google.com",
          resource: {
            timeMin: `${format(new Date(startDate), "yyyy-MM-dd")}T00:00:00+07:00`,
            timeMax: `${format(new Date(startDate), "yyyy-MM-dd")}T23:59:59+07:00`
          }
        }).then((res) => {
          const filteredChan = res?.result.items.filter((el) => {
            if (
              bath !== el.summary.trim()
              &&
              el.start.dateTime
              &&
              el.description
              &&
              start <= +format(new Date(el.end.dateTime), "HH").replace(/^0+/, "")
              &&
              end >= +format(new Date(el.start.dateTime), "HH").replace(/^0+/, "")
            ) {
              return el;
            }
          });

          if (filteredChan.some((item) => item.description.includes("Чан"))) {
            setBusyChan(true);
          } else {
            setBusyChan(false);
          }
        });
      });
    });
  };

  /**Праздники*/
  const [holidays, setHolidays] = useState([]);

  /**Получаем гос праздники из календаря*/
  const getStateHolidays = () => {
    gapi.load("client", () => {
      gapi.client.init({
        apiKey: "AIzaSyAoRRN-VM8H5qgjZGbIQvQEmnKUGIrwrJY",
        discoveryDocs: DISCOVERY_DOCS,
        plugin_name: "streamy"
      }).then(() => {
        return gapi.client.calendar.events.list({
          calendarId: "ru.russian.official#holiday@group.v.calendar.google.com"
        }).then((res) => {
          setHolidays(res?.result.items);
        });
      });
    });
  };

  /**Функция получает и фильтрует события для конкретной даты*/
  const getFilteredEvents = () => {
    setLoading(true);
    let startHours = [];
    let busyStartTimes = [];
    let lastIdx = null;
    //Обнуляем связанные стейты
    setSum(0);
    setStartTime("");
    setEndTime("");
    setTimeError(false);

    //Получаем массив событий из календаря отфильтрованый по выбранной дате
    gapi.load("client", () => {
      gapi.client.init({
        // тестовый ключ
        apiKey: "AIzaSyAoRRN-VM8H5qgjZGbIQvQEmnKUGIrwrJY",
        discoveryDocs: DISCOVERY_DOCS,
        plugin_name: "streamy"
      }).then(() => {
        return gapi.client.calendar.events.list({
          calendarId: "kmkev9s0o0tf198ddtfr8sfgdo@group.calendar.google.com",
          resource: {
            timeMin: `${format(new Date(startDate), "yyyy-MM-dd")}T00:00:00+07:00`,
            timeMax: `${format(new Date(startDate).setDate(new Date(startDate).getDate() + 1), "yyyy-MM-dd")}T03:59:59+07:00`
          }
        }).then((res) => {
          //Фильтруем массив в зависимости от того какая баня выбрана
          const filteredEvents = res?.result.items.filter((item) => {
            if (bath === item.summary.trim()) {
              return item;
            }
          });

          for (let i = 0; i < filteredEvents.length; i++) {
            let start = sunday || saturday ? +format(new Date(filteredEvents[i].start.dateTime), "HH") : +format(new Date(filteredEvents[i].start.dateTime), "HH");
            let end = +format(new Date(filteredEvents[i].end.dateTime), "HH");
            switch (end) {
              case 0:
                end = 24;
                break;
              case 1:
                end = 25;
                break;
              case 2:
                end = 26;
                break;
              case 3:
                end = 27;
                break;
              case 4:
                end = 28;
                break;
            }
            for (let k = start; k <= end; k++) {
              busyStartTimes.push(k);
            }
          }

          startHours = weekdayStartTimes.filter(x => !busyStartTimes.includes(x));

          lastIdx = startHours.length - 1;

          if (startHours[0] + 1 === startHours[1]) {
            temp.push(startHours[0]);
          }

          for (let i = 1; i < startHours.length - 1; i++) {

            if ((startHours[i] + 1 !== startHours[i + 1]) && (startHours[i] - 1 !== startHours[i - 1])) {
              continue;
            }
            temp.push(startHours[i]);
          }

          if (startHours[lastIdx] - 1 === startHours[lastIdx - 1]) {
            temp.push(startHours[lastIdx]);
          }

          startHours = temp;

          setStartTimeslots(startHours);

          if (bath === "Баня по-черному") {
            setDublicateTimeslots(startHours);
          }

          //Если заказ меньше чем за 14 часов то нельзя выбирать с 8 часов
          if (
            +format(new Date(startDate), "t") > +format(sub(startOfTomorrow(), { hours: 6 }), "t")
            &&
            +format(new Date(startDate), "t") < +format(add(startOfTomorrow(), { hours: 10 }), "t")
          ) {
            setStartTimeslots(startHours.filter((t) => {
              return t >= 10;
            }));
          } else {
            setStartTimeslots(startHours);
          }
          setLoading(false);
        });
      });
    });
  };

  /**Увеличение счетчика колличества человек*/
  const incrementPeople = (e) => {

    e.preventDefault();
    if (peopleNum < 10) {
      setPeopleNum(peopleNum + 1);
    }
    if (chan) {
      setChan(false);
    }
    if (attendant) {
      setAttendant(false);
      setSum(sum - costSoaring);
    }
  };

  /**Уменьшение счетчика колличества человек*/
  const decrementPeople = (e) => {
    e.preventDefault();
    if (peopleNum > 1) {
      setPeopleNum(peopleNum - 1);
    }
    if (chan) {
      setChan(false);
    }
    if (attendant) {
      setAttendant(false);
      setSum(sum - costSoaring);
    }
  };

  /**Увеличение счетчика колличества паруемых человек*/
  const incrementSoaringPeople = (e) => {
    e.preventDefault();
    let minutes = (+endTime.replace(":00", "") - +startTime.replace(":00", "")) * 60;
    if ((soaringPeopleNum * 30) < minutes) {
      if (soaringPeopleNum < peopleNum) {
        setSoaringPeopleNum(soaringPeopleNum + 1);
        setSum(sum + costSoaring);
      }
    }
  };

  /**Уменьшение счетчика колличества паруемых человек*/
  const decrementSoaringPeople = (e) => {

    e.preventDefault();
    if (soaringPeopleNum > 1) {
      setSoaringPeopleNum(soaringPeopleNum - 1);
      setSum(sum - costSoaring);
    }
  };

  /**Функция калькулятор рассчета стоимости заказа*/
  const calculateSum = () => {

    // Приводим к нужному формату время начала и время окончания
    let end = +endTime.replace(":00", "");
    let start = +startTime.replace(":00", "");

    // Приводим часы после 24:00 к нужному формату
    switch (end) {
      case 0:
        end = 24;
        break;
      case 1:
        end = 25;
        break;
      case 2:
        end = 26;
        break;
      case 3:
        end = 27;
        break;
      case 4:
        end = 28;
        break;
    }

    //Рассчет стоимости часа с учетом кол-ва человек
    let peopleCostBlack = peopleNum <= 6 ? costBlack : costBlack + ((peopleNum - 6) * overpayment);
    let peopleCostGray = peopleNum <= 6 ? costGray : costGray + ((peopleNum - 6) * overpayment);
    let peopleCostBlackWeekend = peopleNum <= 6 ? costBlackWeekend : costBlackWeekend + ((peopleNum - 6) * overpayment);
    let peopleCostGrayWeekend = peopleNum <= 6 ? costGrayWeekend : costGrayWeekend + ((peopleNum - 6) * overpayment);
    let peopleCostBlackEvening = peopleNum <= 6 ? costBlackEvening : costBlackEvening + ((peopleNum - 6) * overpayment);
    let peopleCostGrayEvening = peopleNum <= 6 ? costGrayEvening : costGrayEvening + ((peopleNum - 6) * overpayment);

    //Рассчитываем сумму исходя из следующих условий:
    //Выбрано время начала
    //Какая баня выбрана
    //Будни или выходной
    //Больше 22:00 или меньше,
    //если больше то стоимость каждого часа после 22:00 увеличивается на 20%
    if (startTime) {
      if (bath === "Баня по-серому") {
        if (end <= 17) {
          setSum((end - start) * peopleCostGray);
        }
        if (end > 17 && start >= 17) {
          setSum((end - start) * peopleCostGrayEvening);
        }
        if (start < 17 && end > 17) {
          setSum(((17 - start) * peopleCostGray) + ((end - 17) * peopleCostGrayEvening));
        }
        if (friday || saturday || sunday) {
          setSum((end - start) * peopleCostGrayWeekend);
        }
      }
      if (bath === "Баня по-черному") {
        if (end <= 17) {
          setSum((end - start) * peopleCostBlack);
        }
        if (end > 17 && start >= 17 ) {
          setSum((end - start) * peopleCostBlackEvening);
        }
        if (start < 17 && end > 17) {
          setSum(((17 - start) * peopleCostBlack) + ((end - 17) * peopleCostBlackEvening));
        }
        if (friday || saturday || sunday) {
          setSum((end - start) * peopleCostBlackWeekend);
        }
      }
    }
  };

  /**Задаем начальные значения времени начала*/
  useEffect(() => {
    gapi.load("client", getFilteredEvents);
  }, []);

  /**Задаем массив с праздниками*/
  useEffect(() => {
    gapi.load("client", getStateHolidays);
  }, []);

  /**Обработчик выбора бани*/
  const handleBath = (event) => {
    setBath(event.target.value);
    setSum(0);
    setStartTime("");
    setEndTime("");
    setAttendant(false);
    setSoaringPeopleNum(1);
    setChan(false);
    setPeopleNum(1);
    setChanVisible(false);
    setAttendantVisible(false);
    setStartDate(null);
    setStartTimeslots([]);
    setEndTimeslots([]);
    setTimeError(false);
  };

  /**Вызываем изменение таймслотов при переключении бани*/
  // useEffect(() => {
  //     if (bath === "Баня по-серому") {
  //       getFilteredEvents();
  //     } else {
  //       setStartTimeslots(dublicateTimeslots);
  //     }
  //   },
  //   [bath, dublicateTimeslots]);

  /**Задаем массив времени окончания*/
  const setEndTimes = (ev) => {
    let endHours = [];
    let start = +ev.target.value.replace(":00", "");
    let idx = startTimeslots.indexOf(start);

    temp = startTimeslots.slice(idx);

    for (let i = 1; i < temp.length; i++) {
      if (temp[i] - 1 !== temp[i - 1]) {
        break;
      } else {
        endHours.push(temp[i]);
      }
    }

    setEndTimeslots(endHours);
  };

  /**скрываем таймслоты времени начала по клику снаружи*/
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      if (!startTimePopup || modalRef.current.contains(event.target)) {
        return false;
      }
      setStartTimePopup(!startTimePopup);
      if (!startTime) {
        setStartTimeError(true);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

  }, [
    startTimePopup,
    modalRef,
    setStartTimePopup,
    startTime
  ]);

  /**Скрываем таймслоты времени окончания по клику снаружи*/
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      if (!endTimePopup || modalRef.current.contains(event.target)) {
        return false;
      }
      setEndTimePopup(!endTimePopup);
      if (!endTime) {
        setEndTimeError(true);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    endTimePopup,
    modalRef,
    setEndTimePopup,
    endTime
  ]);

  /**Пересчитываем сумму при изменении к-ва человек*/
  useEffect(() => {
    if (peopleNum > 5 && peopleNum <= 10) {
      calculateSum();
    }
  }, [peopleNum]);

  /**Пересчитываем сумму при изменении времени окончания*/
  useEffect(() => {
    if (endTime) {
      calculateSum();
    }
  }, [endTime]);

  /**Обрезаем значения больше 22 для формирования списка начала*/
  let cuttingTimeslots = startTimeslots.filter((t) => t <= 22);

  /**Приводим значения к нужному формату после 24:00*/
  let nightTimeslots = endTimeslots.map((t) => {
    if (t === 24) {
      t = 0;
      return t;
    }
    if (t === 25) {
      t = 1;
      return t;
    }
    if (t === 26) {
      t = 2;
      return t;
    }
    if (t === 27) {
      t = 3;
      return t;
    }
    if (t === 28) {
      t = 4;
      return t;
    }
    return t;
  });

  /**Функция делающая время окончания недоступным*/
  const makeEndTimeInactive = (t) => {
    if (t < (+startTime.replace(":00", "") + 2) && t > 4) {
      return true;
    }
    if ((sunday || saturday) && t < (+startTime.replace(":00", "") + 3) && t > 4) {
      return true;
    }
    for (let i = 0; i < holidays.length; i++) {
      let holiday = format(new Date(holidays[i].start.date), "yyyy-MM-dd");
      let date = format(new Date(startDate), "yyyy-MM-dd");
      if (date === holiday && t < (+startTime.replace(":00", "") + 3) && t > 4) {
        return true;
      }
    }
    return false;
  };

  /**Функция делающая время начала недоступным*/
  const makeStartTimeInactive = (t, next) => {
    if (t < currentHour && format(new Date(), "dd.MM.yyyy") === format(new Date(startDate), "dd.MM.yyy")) {
      return true;
    }
    if (next - t > 2) {
      return true;
    }
  };

  /**Пересчитываем сумму при добавлении чана*/
  useEffect(() => {
    let start = +startTime.replace(":00", "");
    let end = +endTime.replace(":00", "");

    // Приводим часы после 24:00 к нужному формату
    switch (end) {
      case 0:
        end = 24;
        break;
      case 1:
        end = 25;
        break;
      case 2:
        end = 26;
        break;
      case 3:
        end = 27;
        break;
      case 4:
        end = 28;
        break;
    }

    // let cost = ((end - start) > 2) ? (costChan + (((end - start) - 2) * 1000)) : costChan;

    if (chan) {
      setSum(sum + costChan);
    } else {
      setSum(sum - costChan);
    }
  }, [chan]);

  /**Пересчитываем сумму при переключении парения*/
  // const handleAttendant = () => {
  //   if (attendant === true) {
  //     setSum(sum - soaringPeopleNum * costSoaring);
  //     setSoaringPeopleNum(1);
  //   } else {
  //     setSum(sum + costSoaring);
  //   }
  // };

  /**Переход на страницу оплаты robokassa*/
  const linkToPayment = () => {

    //описание заказа
    let description = `Имя: ${userName}; Тел: ${userPhone}; Услуга: ${bath}; Дата: ${format(new Date(startDate), "dd.MM.yyyy")} с ${startTime} по ${endTime}; Кол-во чел: ${peopleNum}чел${chan ? '; Чан;' : ''}${attendant ? ' Парение' : ''}`

    //формируем данные для чека
    let receipt = {
      sno: 'osn',
      items: [
        {
          name: bath,
          quantity: 1,
          sum: sum,
          tax: 'none'
        }
      ]
    }

    //делаем из объекта JSON и кодируем для расчета signatureValue
    let receiptUrlSignature = encodeURI(JSON.stringify(receipt))

    //кодируем для ссылки
    let receiptUrlLink = encodeURI(receiptUrlSignature)

    //рассчет контрольной суммы
    let signatureValue = `${process.env.GATSBY_ROBOKASSA_ID}:${sum}:0:${receiptUrlSignature}:${process.env.GATSBY_ROBOKASSA_PASS}`

    //итоговая ссылка
    window.location.href = `https://auth.robokassa.ru/Merchant/Index.aspx?MerchantLogin=${process.env.GATSBY_ROBOKASSA_ID}&OutSum=${sum}&InvId=0&Receipt=${receiptUrlLink}&Description=${description}&SignatureValue=${CryptoJS.MD5(signatureValue).toString()}`
  }

  /**Отправляем данные формы в сервис emailjs*/
  const sendEmail = () => {
    setLoading(true);
    let initialStartHour = startTime.replace(":00", "");
    let initialEndHour = endTime.replace(":00", "");

    let startHour = +initialStartHour < 10 ? `0${initialStartHour}` : initialStartHour;
    let endHour = +initialEndHour < 10 ? `0${initialEndHour}` : initialEndHour;

    let endDate = +initialEndHour >= 0 && +initialEndHour <= 4
      ?
      add(new Date(startDate), { days: 1 })
      :
      startDate;

    const order = {
      summary: bath,
      name: userName,
      phone: userPhone,
      date: format(new Date(endDate), "dd.MM.yyyy"),
      start: `${startHour}:00`,
      end: `${endHour}:00`,
      people: peopleNum,
      att: attendant ? "Парение" : "",
      freeChan: chan ? "Чан," : "",
      summa: sum,
      link: `https://calendar.google.com/calendar/event?action=TEMPLATE&dates=${format(new Date(endDate), "yyyyMMdd")}T${startHour}0000-0700%2F${format(new Date(endDate), "yyyMMdd")}T${endHour}0000-0700&text=${bath}&location=&details=Имя: ${userName}, Телефон: ${userPhone}, Кол-во человек: ${peopleNum}, ${attendant ? "Парение" : ""}, ${chan ? "Чан," : ""} Сумма: ${sum}.&tmsrc=kmkev9s0o0tf198ddtfr8sfgdo%40group.calendar.google.com`
    };

    emailjs.send(
      process.env.GATSBY_SERVICE_ID,
      process.env.GATSBY_TEMPLATE_ID,
      order,
      process.env.GATSBY_PUBLIC_KEY
    )
      .then((result) => {
        linkToPayment();
      }, (error) => {
        console.log(error.text);
        setLoading(false);
        closeModal();
        openErrorModal();
      });
  };

  const openLockModal = (d) => {
    let startHoliday = Date.parse(startHolidays)
    let endHoliday = Date.parse(endHolidays)
    let currDate = Date.parse(format(new Date(d), 'yyyy-MM-dd'))
    let newDate = Date.parse(format(new Date(), 'yyyy-MM-dd'))
    let currHour = new Date().getHours()
    if(currDate >= startHoliday && currDate <= endHoliday) {
      setSuccessfulModal(true)
    }
    if((currHour >= 0) && (currHour < 8) && (currDate === newDate) ) {
      setNightLockModal(true)
    }
  }

  return (
    <div className="container container__order">
      <div className="text-center main-title-block"><h2 className="with-underline about-main-title">Оформление
        заказа</h2></div>
      <div className="main-content main-content__form">
        <form
          autocomplete="off"
          name="google-sheet"
          className="order-form"
        >
          {/**Выбор бани*/}
          <div className="form-row__radio">
            <label className="form-radio__label">
              <input
                className="form-radio__input"
                type="radio"
                name="bath"
                checked={bath === "Баня по-черному"}
                value="Баня по-черному"
                onChange={(e) => handleBath(e)}
              />
              <div className="form-radio__text">
                <span className="form-radio__fake"></span>
                <span>Баня по-черному</span>
              </div>
            </label>
            <label className="form-radio__label">
              <input
                className="form-radio__input"
                type="radio"
                checked={bath === "Баня по-серому"}
                name="bath"
                value="Баня по-серому"
                onChange={(e) => handleBath(e)}
              />
              <div className="form-radio__text">
                <span className="form-radio__fake"></span>
                <span>Баня по-серому</span>
              </div>
            </label>
          </div>
          <div className="form-row form-row__time">
            {/**Выбор даты*/}
            <div className="form-group form-group__time">
              <label className="form-name" htmlFor="date">Дата</label>
              <div
                onClick={() => {
                setEndTimePopup(false);
                setStartTimePopup(false);
                setChan(false);
                setChanVisible(false);
                setAttendantVisible(false);
              }}
              >
                <DatePicker
                  id="date"
                  name="date"
                  dateFormat="dd.MM.yy"
                  className={`${dateError ? "red-border" : ""}`}
                  selected={startDate}
                  minDate={new Date()}
                  maxDate={addDays(new Date(), 30)}
                  onChange={(date) => {
                    setStartDate(date);
                    openLockModal(date)
                  }}
                  onCalendarClose={getFilteredEvents}
                  locale="ru"
                  placeholder="data"
                />
                {
                  (dateError) &&
                  <div className="form-error form-error__date">Выберите дату</div>
                }
              </div>
            </div>
            {/**Выбор начала заказа*/}
            <div className="form-group form-group__time">
              <label className="form-label">
                <span className="form-name">Время от:</span>
                <input
                  type="button"
                  className={`${startTimeError ? "form-control form-active" : "form-control"}`}
                  value={startTime}
                  onClick={(e) => {
                    startDate ? setDateError(false) : setDateError(true);
                    setStartTimePopup(true);
                    setEndTimePopup(false);
                    setSoaringPeopleNum(1);
                    if (attendant) {
                      setAttendant(false);
                    }
                    if (chan) {
                      setChan(false);
                    }
                    setChanVisible(false);
                    setAttendantVisible(false);
                    startDate && setDateError(false)
                  }}
                />
                {
                  (endTimeError || startTimeError) &&
                  <div className="form-error form-error__time ">Выберите время</div>
                }
              </label>
              {
                startTimePopup &&
                <div ref={modalRef}>
                  <div className="hidden-triangle"></div>
                  <div className="hidden-timeslots">
                    <div className="hidden-title">
                      <h5>{startDate && startDate.toLocaleDateString()}</h5>
                      <span>со скольки бы хотели начать?</span>
                    </div>
                    {
                      loading ? (
                        <div className="loader-wrapper">
                          <Loader variant='dot-black'/>
                        </div>
                      ) : (
                        <div className="hidden-buttons">
                          {
                            cuttingTimeslots.length
                              ?
                              cuttingTimeslots.map((time, idx, arr) => {
                                return (
                                  <input
                                    key={time}
                                    type="button"
                                    name="startTime"
                                    className="timeslot-btn"
                                    value={`${time}:00`}
                                    disabled={makeStartTimeInactive(time, arr[idx + 2])}
                                    onClick={(e) => {
                                      setEndTimes(e);
                                      setStartTime(e.target.value);
                                      setStartTimePopup(false);
                                      setSum(0);
                                      setEndTime("");
                                      if (e.target.value) {
                                        setStartTimeError(false);
                                      }
                                      setTimeError(false);

                                    }}
                                  />
                                );
                              })
                              :
                              "На эту дату нет свобоных часов!"
                          }
                        </div>
                      )
                    }
                  </div>
                </div>
              }
            </div>
            {/**Выбор окончания заказа*/}
            <div className="form-group form-group__time">
              <label className="form-label">
                <span className="form-name">Время до:</span>
                <input
                  type="button"
                  className={`${endTimeError ? "form-control form-active" : "form-control"}`}
                  value={endTime}
                  onClick={(e) => {
                    setEndTimePopup(true);
                    setStartTimePopup(false);
                    setSoaringPeopleNum(1);
                    if (startTime && endTime) {
                      setEndTime("");
                      setChanVisible(false);
                      setAttendantVisible(false);
                    }
                    if (attendant) {
                      setAttendant(false);
                    }
                    if (chan) {
                      setChan(false);
                    }
                  }}
                />
              </label>
              {
                endTimePopup &&
                <div ref={modalRef}>
                  <div className="hidden-triangle"></div>
                  <div className="hidden-timeslots hidden-timeslots__end">
                    <div className="hidden-title">
                      <h5>{startDate && startDate.toLocaleDateString()}</h5>
                      <span>во сколько бы хотели закончить?</span>
                    </div>
                    {
                      loading ? (
                        <div className="loader-wrapper">
                          <Loader variant='dot-black'/>
                        </div>
                      ) : (
                        <div className="hidden-buttons">
                          {
                            nightTimeslots.map((time) => {
                              return (
                                <input
                                  key={time}
                                  type="button"
                                  name="endTime"
                                  className="timeslot-btn"
                                  value={`${time}:00`}
                                  disabled={makeEndTimeInactive(time)}
                                  onClick={(e) => {
                                    setEndTime(e.target.value);
                                    setEndTimePopup(false);
                                    if (e.target.value) {
                                      setEndTimeError(false);
                                    }
                                    if (e.target.value && startTime) {
                                      setTimeError(true);
                                    }
                                    setChanVisible(true);
                                    setAttendantVisible(true);
                                    checkChan(e);
                                  }}
                                />
                              );
                            })
                          }
                        </div>
                      )
                    }
                  </div>
                </div>
              }
            </div>
            {/**Выбор колличества человек*/}
            <div className="form-group">
              <label className="form-label">
                <span className="form-name">Гостей</span>
                <div className="form-control form-control__count">
                  <button className="form-count__btn" onClick={decrementPeople}>-</button>
                  <input
                    className="form-count__input"
                    type="text"
                    name="peopleNum"
                    onChange={(e) => {
                    }}
                    value={peopleNum}
                  />
                  <button className="form-count__btn" onClick={incrementPeople}>+</button>
                </div>
              </label>
            </div>
          </div>
          {/**Имя и Телефон*/}
          {
            (bath && startDate && startTime && endTime) &&
            <div className="form-row__radio">
              <div className="form-group">
                <label className="form-label">
                  <InputMask
                    mask="+7(999) 999 99 99"
                    value={userPhone}
                    onChange={(e) => {
                      setUserPhone(e.target.value)
                    }}
                    className={`${phoneError ? "form-control form-active" : "form-control"}`}
                    placeholder="Телефон"
                    onBlur={(e) => {
                      if(!e.target.value) {
                        setPhoneError(true)
                      } else {
                        setPhoneError(false)
                      }
                    }}
                  >
                  </InputMask>
                  {
                    (phoneError) &&
                    <div className="form-error form-error__time ">Введите телефон</div>
                  }
                </label>
              </div>
              <div className="form-group">
                <label className="form-label">
                  <input
                    type="text"
                    className={`${nameError ? "form-control form-active" : "form-control"}`}
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                      if (e.target.value.length > 0) {
                        setNameError(false)
                      } else {
                        setNameError(true)
                      }
                    }}
                    placeholder="Имя"
                    // onBlur={(e) => {
                    //   if(!e.target.value) {
                    //     setNameError(true)
                    //   } else {
                    //     setNameError(false)
                    //   }
                    // }}
                  />
                  {
                    (nameError) &&
                    <div className="form-error form-error__time ">Введите имя</div>
                  }
                </label>
              </div>
            </div>
          }
          {/**Выбор чана*/}
          <div className="form-row form-row__checkboxes">
            {
              bath && new Date(startDate).toDateString() !== new Date().toDateString() && chanVisible && !busyChan &&
              <>
                <div className="form-group form-group__checkboxes">
                  <input
                    type="checkbox"
                    checked={chan}
                    id="chan"
                    name="chan"
                    value="false"
                    className="order-checkbox"
                    onChange={(e) => {
                      setChan(!chan);
                    }}
                  />
                  <label htmlFor="chan" className="order-label">
                    {" "}
                    Чан с травами
                  </label>
                </div>
              </>
            }
          </div>
          {/**Выбор парильщика*/}
          {
            attendantVisible &&
            <div className="form-row form-row__checkboxes">
              <div className="form-group form-group__checkboxes">
                <input
                  type="checkbox"
                  checked={attendant}
                  id="attendant"
                  name="attendant"
                  value="false"
                  className="order-checkbox"
                  onChange={(e) => {
                    setAttendant(!attendant);
                    // handleAttendant();
                  }}
                />
                <label htmlFor="attendant" className="order-label">
                  {" "}
                  Парение
                </label>
              </div>
              {/*{*/}
              {/*  attendant &&*/}
              {/*  <div className="form-group form-group__count">*/}
              {/*    <div className="form-label__wrap">*/}
              {/*      <label className="form-label">*/}
              {/*        <span className="form-name">Гостей</span>*/}
              {/*        <div className="form-control form-control__count">*/}
              {/*          <button className="form-count__btn" onClick={decrementSoaringPeople}>-</button>*/}
              {/*          <input*/}
              {/*            className="form-count__input"*/}
              {/*            type="text"*/}
              {/*            name="soaringPeopleNum"*/}
              {/*            onChange={(e) => {*/}
              {/*            }}*/}
              {/*            value={soaringPeopleNum}*/}
              {/*          />*/}
              {/*          <button className="form-count__btn" onClick={incrementSoaringPeople}>+</button>*/}
              {/*        </div>*/}
              {/*      </label>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*}*/}
            </div>
          }
          {/**Итоговая цена*/}
          <div className="form-row form-row__control">
            <div className="form-group form-group__sum">
              <span className="amount form-text">{endTime && `Итого: ${sum} руб`}</span>
            </div>
            <button
              className="snipcart-add-item order-btn order-btn__mobile"
              disabled={!disable}
              onClick={openModal}
            >
              оформить
            </button>
          </div>
        </form>
        <aside className="form-sidebar">
          <div className="form-img-wrap">
            <img
              className="form-img"
              src={bath === "Баня по-черному" ? blackBath : grayBath}
              alt={bath === "Баня по-черному" ? "Баня по-черному" : "Баня по-серому"}
              title={bath === "Баня по-черному" ? "Баня по-черному" : "Баня по-серому"}
            />
          </div>
          <div>
            <span className="form-alert-title">{bath}</span>
            <hr style={{ marginBottom: "10px" }} />
            <span className="asterisk">*Стоимость услуг соответствует <Link to="/price/" className="asterisk">Прайсу</Link>!</span><br/>
            <span className="asterisk">*Проверить чек можно в приложении "Мои чеки"!</span><br/>
            <span className="asterisk">*Парение не входит в стоимость заказа!</span>
          </div>

          <button
            className="snipcart-add-item order-btn"
            disabled={!disable}
            onClick={openModal}
          >
            оформить
          </button>
        </aside>
      </div>
      {
        modalIsOpen && (
          <OrderModal
            closeModal={closeModal}
            modalIsOpen
            bath={bath}
            sum={sum}
            date={startDate}
            start={startTime}
            end={endTime}
            people={peopleNum}
            chan={chan}
            attendant={attendant}
            submit={sendEmail}
            load={loading}
            soaring={soaringPeopleNum}
            removeName={setUserName}
            removePhone={setUserPhone}
            removeBath={setBath}
            removeDate={setStartDate}
            removeStart={setStartTime}
            removeEnd={setEndTime}
            removePeople={setPeopleNum}
            removeChan={setChan}
            removeAttendant={setAttendant}
            removeSoaring={setSoaringPeopleNum}
            removeChanVisible={setChanVisible}
            removeSum={setSum}
            removeAttendantVisible={setAttendantVisible}
            setTimeError={setTimeError}
            setNameError={setNameError}
            setPhoneError={setPhoneError}
          />
        )
      }
      {
        successfulModal && (
          <SuccessfulModal
            closeSuccessfulModal={closeSuccessfulModal}
            successfulModal
            startHolidays={startHolidays}
            endHolidays={addDays(new Date(endHolidays), -1)}
          />
        )
      }
      {
        nightLockModal && (
          <NightLockModal
            closeLockModal={() => setNightLockModal(false)}
            nightLockModal
          />
        )
      }
      {/*{*/}
      {/*  errorModal && (*/}
      {/*    <ErrorModal*/}
      {/*      closeErrorModal={closeErrorModal}*/}
      {/*      errorModal*/}
      {/*    />*/}
      {/*  )*/}
      {/*}*/}
    </div>
  );
};

export default Form;
