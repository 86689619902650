import photo6 from "../images/black.webp";
import photo5 from "../images/Description/bany_ser.webp";

export const bannerSlider = [
  {
    id: 1,
    image: "https://res.cloudinary.com/dlhsp2kx2/image/upload/c_scale,q_auto:eco,w_1920/v1694672250/main_page/photo-205_1_lawrdk.webp",
    slug: 'banya-po-chernomu',
    title: 'Баня по-чёрному',
    adress: 'п. Затон ул.Водников 1е',
  },
  {
    id: 2,
    image: "https://res.cloudinary.com/dlhsp2kx2/image/upload/c_scale,q_auto:eco,w_1920/v1694672390/main_page/photo-188_1_latzou.webp",
    slug: 'banya-po-seromu',
    title: 'Баня по-серому',
    adress: 'п. Затон ул.Водников 1е'
  }
]