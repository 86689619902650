import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#___gatsby");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#E19C67",
    padding: "5px"
  }
};

const ErrorModal = ({errorModal, closeErrorModal}) => {

  return (
    <div>
      <Modal
        isOpen={errorModal}
        style={customStyles}
        onRequestClose={closeErrorModal}
        contentLabel="Example Modal"
      >
        <div className="main-content main-content__modal">
          <h2>Что-то пошло не так! Приносим свои извинения!</h2>
          <p>
            Оформить заказ вы можете по телефону:
            {" "}
            <a href="tel:+73852235555">+7 964 087-55-55</a>
          </p>
          <div className="modal-control">
            <button
              onClick={closeErrorModal}
              aria-label="close modal"
              className="modal-btn"
            >
              Закрыть
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ErrorModal;