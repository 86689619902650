import React from "react";
import Modal from "react-modal";
import {Link} from "gatsby"

Modal.setAppElement("#___gatsby");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#E19C67",
    padding: "5px"
  }
};

const NightLockModal = ({nightLockModal, closeLockModal}) => {

  return (
    <div>
      <Modal
        isOpen={nightLockModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="main-content main-content__modal">
          <h2 className="successful-title">Заказать баню в текущее время невозможно</h2>
          <h2 className="successful-title">Повторите попытку утром или выберите другую дату</h2>
          <p className="successful-text">Приносим извинения за доставленные неудобства</p>
          <p className="successful-text">С уважением администрация "Курорта Первый"!</p>
          <div className="modal-control">
            <Link to="/" className="modal-link">
              <button
                onClick={closeLockModal}
                aria-label="close modal"
                className="modal-btn"
              >
                Закрыть
              </button>
            </Link>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NightLockModal;