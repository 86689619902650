import React from 'react';

const Loader = ({variant}) => {
  return (
    <div className="dot-wrapper">
      <div className={`dot dot-1 ${variant}`}/>
      <div className={`dot dot-2 ${variant}`}/>
      <div className={`dot dot-3 ${variant}`}/>
    </div>
  );
};

export default Loader;
