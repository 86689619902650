/**
 баннер страницы
 */
import React from "react";
import SlickSlider from "./slider";
import {Link} from "gatsby";
import {bannerSlider} from "../consts/bannerSlider";

/**Настройки слайдера*/
let settings = {
  arrows: false,
  dots: true,
  speed: 2000,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1
};

const Banner = ({buttonText}) => {

  return (
    <SlickSlider settings={settings}>
      {bannerSlider.map((slide) => (
        <div key={slide.id} className="item">
          <div className="site-Banner">
            <img src={slide.image} alt={slide.title} title={slide.title} className="default-Img"/>
            <div className="Banner-details">
              <div>
                <h1>{slide.title}</h1>
                <span className="banner-adress"><i className="fa fa-map-marker-alt"/>{' '}{slide.adress}</span><br/>
                <Link to={`/description/`}>{buttonText}</Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </SlickSlider>
  );
}

export default Banner;