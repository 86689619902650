import React from "react";
import Modal from "react-modal";
import {Link} from "gatsby"
import {
  format,
  addDays
} from "date-fns";
import ru from "date-fns/locale/ru";

Modal.setAppElement("#___gatsby");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#E19C67",
    padding: "5px"
  }
};

const SuccessfulModal = ({successfulModal, closeSuccessfulModal, startHolidays, endHolidays}) => {

  return (
    <div>
      <Modal
        isOpen={successfulModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="main-content main-content__modal">
          {/*<h2 className="successful-title">Заказать баню с 30 декабря 2022 по 8 января 2023</h2>*/}
          <h2 className="successful-title">{`Заказать баню с ${format(new Date(startHolidays), 'dd MMMM yyyy', {locale: ru})} по ${format(new Date(endHolidays), 'dd MMMM yyyy', {locale: ru})}`}</h2>
          <h2 className="successful-title">Можно по телефону <a href="tel:+79640875555"> +7 964 087-55-55</a></h2>
          <p className="successful-text">Приносим извинения за доставленные неудобства</p>
          <p className="successful-text">С уважением администрация "Курорта Первый"!</p>
          {/*<p className="successful-text">*/}
          {/*  Наш администратор свяжется с Вами в ближайшее время.*/}
          {/*  <br/>*/}
          {/*  Телефон для справки:*/}

          {/*  <a href="tel:+79640875555"> +7 964 087-55-55</a>*/}

          {/*</p>*/}
          <div className="modal-control">
            <Link to="/" className="modal-link">
              <button
                onClick={closeSuccessfulModal}
                aria-label="close modal"
                className="modal-btn"
              >
                Закрыть
              </button>
            </Link>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SuccessfulModal;